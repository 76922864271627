<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax class="page-header-image"
                style="background-image: url('img/bg29.jpg')">
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Regulamin</h1>
            <h4>korzystania z serwisu etiming.pl</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ">
            <h2>
              I. Postanowienia ogólne
            </h2>
            <p>
              Portal internetowy etiming.pl jest prowadzony i zarządzany przez firmę <strong>INNOV8TECH</strong> Tomasz
              Dziorek, ul. Zakole 2/48, 39-432 Gorzyce w całości, posiadający numer NIP: 8672081492.
            </p>
            <p>
              Niniejszy regulamin określa zasady korzystania i funkcjonowania Portalu. Postanowienia Regulaminu oraz
              wszelkich załączników i dodatków (w tym regulamin własny serwisu z pkt.1.1.), będących jego integralnymi
              częściami wraz z przepisami prawa obowiązującego na terytorium Rzeczypospolitej Polskiej, w sposób
              wyłączny określają prawa i obowiązki Użytkowników Portalu, a także prawa, obowiązki oraz zakres
              odpowiedzialności Usługodawcy. Każdy Użytkownik zobowiązany jest, z momentem podjęcia czynności
              zmierzających do korzystania z Portalu, do przestrzegania postanowień niniejszego Regulaminu. Przed
              założeniem Konta należy uważnie przeczytać poniższy Regulamin.
            </p>
            <h2>
              II. Definicje
            </h2>
            <p>
              <strong>Regulamin</strong> – oznacza niniejszy Regulamin świadczenia usług w Portalu.
            </p>
            <p>
              <strong>Portal</strong> – oznacza serwis internetowy: etiming.pl
            </p>
            <p>
              <strong>Usługodawca</strong> – oznacza firmę INNOV8TECH Tomasz Dziorek, wpisany do Centralnej Ewidencji i
              Informacji o Działalności Gospodarczej prowadzonej przez Ministra Rozwoju i Finansów, NIP 8672081492, ul.
              Zakole 2/48, 39-432 Gorzyce.
            </p>
            <p>
              <strong>Użytkownik</strong> – oznacza osobę fizyczną lub podmiot korzystający z ogólnie dostępnych usług
              oferowanych przez Portal.
            </p>
            <p>
              <strong>Organizator</strong> – podmiot niebędący konsumentem w rozumieniu przepisów ustawy Kodeks cywilny,
              korzystający z Serwisu w związku z organizacją przez siebie Imprezy Sportowej w tym Imprezy z opłatami
              startowymi, w szczególności w celu umożliwienia Uczestnikom dokonania zapisu na Imprezę, a także
              udostępnienia im za pośrednictwem Serwisu wyników Imprezy
            </p>
            <p>
              <strong>Zarejestrowany użytkownik</strong> – oznacza osobę fizyczną lub podmiot, który skutecznie założy
              Profil w Portalu poprzez uzyskanie w procesie rejestracji indywidualnego numeru karty klienta oraz
              wybranego przez siebie hasła pozwalającego zalogować się do Profilu.
            </p>
            <p>
              <strong>Profil</strong> – zbiór informacji opisujących i identyfikujących danego Zarejestrowanego
              Użytkownika Portalu, przekazywanych samodzielnie i dobrowolnie przez Zarejestrowanego Użytkownika do
              Portalu, które przetwarzane są i publikowane na stronach Portalu zgodnie z Ustawą o Ochronie Danych
              Osobowych z dnia 29 sierpnia 1997r. z późniejszymi zmianami.
            </p>
            <p>
              <strong>Baza Profili</strong> – zbiór informacji opisujących i identyfikujących wszystkich Użytkowników
              Portalu, przekazywanych samodzielnie i dobrowolnie przez wszystkich Użytkowników do Portalu, które
              gromadzone są i przetwarzane przez Portal na potrzeby świadczenia usług Portalu.
            </p>
            <p>
              <strong>Operator płatności – TPAY.COM</strong> – System płatności Tpay.com, który należy do spółki,
              Krajowy Integrator Płatności S.A. ul. Św. Marcin 73/6, 61-808 Poznań, NIP: 7773061579, REGON: 300878437,
              KRS: 0000412357
            </p>
            <p>
              <strong>Siła wyższa</strong> – wszelkie nagłe zdarzenia o charakterze zewnętrznym, powodujące obiektywną
              niemożność wykonania świadczeń objętych Regulaminem, których Usługodawca nie był w stanie przewidzieć.
            </p>
            <h2>
              III. Ogólne warunki korzystania z systemu
            </h2>
            <p>
              Do korzystania z usług Portalu niezbędne jest posiadanie urządzenia pozwalającego na korzystanie z sieci
              internetowej, przeglądarki internetowej z włączoną obsługą plików „cookies”, skrzynki poczty
              elektronicznej e-mail.
            </p>
            <p>
              Podstawową działalnością Portalu w sieci internetowej jest:
            </p>
            <p>
              - umożliwienie Zarejestrowanym Użytkownikom zapisywania się w sposób elektroniczny na zawody sportowe
              organizowane przez osoby i/lub podmioty trzecie na podstawie własnych regulaminów zawodów oraz warunków
              uczestnictwa w takich zawodach,
            </p>
            <p>
              - publikacja wyników z zawodów sportowych, które przygotowane zostały przez INNOV8TECH lub podmioty
              trzecie,
            </p>
            <p>
              - publikacja zdjęć i materiałów audiowizualnych z zawodów sportowych w celach umożliwienia ich zakupu
              przez Zarejestrowanych Użytkowników,
            </p>
            <p>
              - na podstawie oddzielnych umów umożliwienie organizatorom zawodów sportowych przeprowadzenia zapisów
              elektronicznych na te zawody dla Zarejestrowanych Użytkowników,
            </p>
            <p>
              - na podstawie oddzielnych umów publikacja wyników z zawodów sportowych, które przygotowane zostały przez
              INNOV8TECH lub podmioty trzecie.
            </p>
            <p>
              Rejestracja Profilu w Portalu jest dobrowolna i bezpłatna. Każdy z Zarejestrowanych Użytkowników ma prawo
              do wglądu do swoich danych przetwarzanych przez Portal, prawo do poprawiania tych danych oraz do żądania
              zaprzestania ich przetwarzania poprzez usunięcie z Bazy Profili.
            </p>
            <p>
              Zarejestrowani Użytkownicy są zobowiązani do niezwłocznego aktualizowania zapisanych w Profilu danych w
              przypadku każdej ich zmiany, w szczególności jeśli zmiany te mają istotny wpływ na uczestnictwo w zawodach
              sportowych. Wszelkie konsekwencje podania nieprawdziwych danych ponosi Użytkownik.
            </p>
            <p>
              Konta powiązane, to profile innych Zarejestrowanych Użytkowników Portalu przypisane do danego
              Zarejestrowanego Użytkownika. Dzięki tym powiązaniom można zgłaszać na zawody sportowe swoich znajomych
              i/lub odwrotnie. W ramach kont powiązanych widoczne są tylko wybrane dane Profilu: imię, nazwisko,
              miejscowość, klub sportowy. Dane konta powiązanego mogą być aktualizowane tylko przez osobę, której one
              dotyczą. Powiązanie kont następuje w wyniku dobrowolnego przekazania innemu Zarejestrowanemu Użytkownikowi
              swojego numeru karty Użytkownika oraz indywidualnego kodu udostępniania wygenerowanego przez Portal.
            </p>
            <p>
              Zapisy elektroniczne na zawody, to formularze dostępne przez przeglądarkę internetową, spersonalizowane
              według danych Profilu oraz wymagań organizatora zawodów. Każdy z Użytkowników może skorzystać z
              udostępnionych formularzy w celu zapisania się na zawody sportowe.
            </p>
            <p>
              Wypełniając formularz zapisów elektronicznych, Użytkownik wyraża zgodę na przetwarzanie danych podanych w
              tym formularzu przez Usługodawce oraz organizatora zawodów sportowych, których ten formularz dotyczy
              zgodnie z Ustawą o Ochronie Danych Osobowych z dnia 29 sierpnia 1997r. z późniejszymi zmianami.
              Wypełniając formularz zapisów elektronicznych, Użytkownik wyraża zgodę na otrzymywanie na wskazany adres
              e-mail powiadomień generowanych przez Portal oraz na otrzymywanie na ten adres e-mail wiadomości od
              Usługodawcy i/lub organizatora dotyczących zawodów, na które został wypełniony formularz zapisów.
            </p>
            <p>
              Wyniki online, to listy wyników z zawodów sportowych prezentowane przez Usługodawce na zlecenie
              organizatorów zawodów sportowych. Wyniki online zawierają informacje o zawodnikach oraz ich rezultaty na
              zawodach sportowych.
            </p>
            <h2>
              IV. Organizacja zapisów na Imprezy i publikowanie wyników
            </h2>
            <p>
              1. Organizacja zapisów: Organizator może umożliwiać zapisy na Imprezę Sportową oraz publikować wyniki
              Uczestników w Serwisie.<br>
              2. Zasady Imprezy: Organizator musi dostarczyć Zasady Imprezy, zgodne z przepisami prawa i zasadami
              współżycia społecznego, oraz zadbać o zgodne z prawem przetwarzanie danych osobowych, informując o
              publikacji wyników.<br>
              3. Informacja o wynikach: Organizator musi poinformować Uczestników o publikacji wyników na listach
              wyników.<br>
              4. Odpowiedzialność: Organizator jest odpowiedzialny za organizację i przebieg Imprezy Sportowej, w tym za
              Zasady Imprezy.<br>
              5. Opłaty Startowe: Organizator może pobierać Opłaty Startowe, informując o ich wysokości w Serwisie.<br>
              6. Pobieranie opłat przez Usługodawcę: Jeśli Usługodawca jest Organizatorem, Opłaty Startowe mogą być
              pobierane przez Usługodawcę.<br>
              7. Publikacja wyników: Organizator może publikować wyniki Imprezy Sportowej, w tym dane osób niebędących
              Użytkownikami.
            </p>
            <h2>
              V. Zapisy na imprezy
            </h2>
            <p>
              1. Zapisy Użytkowników: Użytkownik może zapisać się na dowolną Imprezę Sportową dostępną w Serwisie, chyba
              że Zasady Imprezy stanowią inaczej.<br>
              2. Publiczne wyniki: Wyniki osiągnięte w ramach współzawodnictwa sportowego są publiczne i mogą zawierać:
              imię i nazwisko, osiągnięty wynik, miejscowość, kategorię.<br>
              3. Zapisy osób trzecich: Użytkownik może zapisać na Imprezę Sportową osobę trzecią, z zastrzeżeniem,
              że:<br>
              a. W przypadku osób poniżej 16 roku życia - musi być rodzicem lub opiekunem prawnym i wyrazić zgodę na
              przetwarzanie danych osobowych dziecka.<br>
              b. W przypadku innych osób - musi mieć stosowne upoważnienie i poinformować tę osobę o polityce
              prywatności i publikacji wyników.<br>
              4. Zasady Imprezy: Zasady Imprezy określają prawa Uczestnika oraz zasady rezygnacji i odstąpienia od
              umowy.
            </p>
            <h2>
              VI. Ochrona danych osobowych
            </h2>
            <p>
              1. Administratorem danych osobowych Użytkowników oraz Organizatorów jest Usługodawca.&nbsp;<br>
              2. Dane osobowe Użytkownika przetwarzane są w szczególności w celu realizacji Umowy, szczegółowe zasady
              ich przetwarzania określa polityka prywatności Serwisu.&nbsp;<br>
              3. Administratorem danych osobowych Uczestników jest Organizator, który określa zasady przetwarzania ich
              danych osobowych<br>
              4. Organizator powierza Usługodawcy przetwarzanie danych osobowych Uczestników.<br>
              5. Użytkownik zapisując osobę trzecią na Imprezę Sportową potwierdza, że jest umocowany do podania jej
              danych Organizatorowi i zobowiązuje się do przekazania tej osobie wszelkich przekazanych mu informacji
              dotyczących przetwarzania jej danych przez Organizatora.
            </p>
            <h2>
              VII. Płatności
            </h2>
            <p>
              Za realizację Usług (tj. zawodów sportowych) dostępnych w Portalu eTiming.pl, Usługodawca może pobierać na
              rzecz organizatora zawodów sportowych opłaty zgodnie z regulaminem poszczególnych zawodów. Podane ceny są
              cenami brutto, zawierającymi podatek VAT według stawki obowiązującej danego organizatora zawodów.
            </p>
            <p>
              Płatności elektroniczne realizowane są za pośrednictwem automatycznego systemu płatności internetowych
              TPAY.com.
            </p>
            <p>
              Do każdej wpłaty poprzez Portal i TPAY.com dołączany jest paragon, a na życzenie faktura VAT do tego
              paragonu.
            </p>
            <h2>
              VIII. Odstąpienie od umowy
            </h2>
            <p>
              1. Odstąpienie od umowy: Uczestnik ma prawo odstąpić od umowy uczestnictwa w Imprezie Sportowej zgodnie z
              Zasadami Imprezy.<br>
              2. Zwrot Opłaty Startowej: Zwrot Opłaty Startowej może nastąpić tylko za zgodą Organizatora. Warunki i
              procedura zwrotu są określone w Zasadach Imprezy.<br>
              3. Termin odstąpienia: Uczestnik powinien zgłosić chęć odstąpienia od umowy w terminie określonym w Zasadach
              Imprezy.<br>
              4. Forma zgłoszenia: Odstąpienie od umowy powinno być zgłoszone w formie pisemnej lub elektronicznej zgodnie
              z instrukcjami podanymi przez Organizatora w Zasadach Imprezy.
            </p>
            <h2>
              IX. Reklamacje
            </h2>
            <p>
              1. Zgłoszenia reklamacyjne dotyczące funkcjonowania Serwisu należy zgłaszać do Usługodawcy.&nbsp;<br>
              2. Reklamacje mogą być składane:&nbsp;<br>
              - drogą elektroniczną: <a href="mailto:contact@etiming.pl">contact@etiming.pl</a>&nbsp;<br>
              - telefonicznie: 663-202-929&nbsp;<br>
              - korespondencyjnie: ul. Zakole 2/48, 39-432 Gorzyce<br>
              3. Reklamacja powinna zawierać następujące informacje:<br>
              - Imię i nazwisko uczestnika,<br>
              - Numer startowy uczestnika,<br>
              - Datę i nazwę biegu,<br>
              - Opis problemu i powód zgłoszenia reklamacji,<br>
              - Dowód dokonania opłaty (np. potwierdzenie przelewu),<br>
              - Adres korespondencyjny oraz adres e-mail do kontaktu.<br>
              4. W celu zapewnienia najwyższej jakości obsługi procesu reklamacyjnego Usługodawca rekomenduje aby
              reklamacja zawierała oznaczenie Użytkownika lub Organizatora zgłaszającego reklamację, a także zwięzły
              opis przedmiotu reklamacji.&nbsp;<br>
              5. Usługodawca ustosunkuje się do reklamacji w terminie 14 dni od momentu otrzymania zgłoszenia
              reklamacyjnego.&nbsp;<br>
              6. Reklamacje dotyczące Imprez Sportowych, w tym wyników tych Imprez i list wyników powinny być zgłaszane
              bezpośrednio do Organizatora. Zasady ich rozpatrywania określa Organizator.
            </p>
            <h2>
              X. Prawa autorskie
            </h2>
            <p>
              Prawa autorskie do zamieszczonych w serwisie treści, plików oraz materiałów audiowizualnych przysługują
              INNOV8TECH. Użytkownik nabywa prawo niewyłącznej licencji prywatnej do zakupionych w Portalu plików oraz
              materiałów audiowizualnych.
            </p>
            <p>
              Osoby lub Firmy zainteresowane innym niż prywatne wykorzystaniem treści dostępnych w Portalu proszone są o
              kontakt z Portalem.
            </p>
            <h2>
              XI. Postanowienia końcowe
            </h2>
            <p>
              Usługodawca nie odpowiada w żaden sposób za prawdziwość informacji wprowadzanych przez Użytkowników do
              Portalu poprzez dostępne dla nich formularze.
            </p>
            <p>
              Usługodawca nie ponosi odpowiedzialności za „kradzieże kont Profili” dokonywane przez osoby trzecie, w
              szczególności na skutek niewłaściwej ochrony własnego hasła do konta przez Zarejestrowanego Użytkownika.
            </p>
            <p>
              Usługodawca zastrzega sobie prawo do wprowadzania nowych funkcjonalności i usług dostępnych w Portalu,
              przeprowadzania i odwoływania wszelkiego rodzaju akcji promocyjnych, prac konserwacyjnych Portalu, w tym
              do czasowego wyłączania części lub wszystkich stron Portalu.
            </p>
            <p>
              Usługodawca zastrzega sobie prawo do wprowadzenia zmian w regulaminie. W przypadku wprowadzenia zmian w
              Regulaminie, Usługodawca poinformuje o tym fakcie na stronach głównych serwisów wchodzących w skład
              Portalu. Po ukazaniu się informacji o zmianach w Regulaminie Użytkownik powinien niezwłocznie zapoznać się
              ze zmianami. Rejestracja nowego konta lub zalogowanie się na istniejące konto przez Użytkownika oznacza
              bezwarunkową akceptację nowej treści Regulaminu przez Użytkownika. Użytkownik w przypadku nie
              zaakceptowania nowej treści regulaminu powinien niezwłocznie poinformować o tym fakcie Usługodawcę.
              Oświadczenie o nie zaakceptowaniu nowej treści Regulaminu skutkuje usunięciem konta Zarejestrowanego
              Użytkownika. W sprawach nie uregulowanych niniejszym regulaminem stosuje się przepisy Kodeksu Cywilnego.
              Sądem właściwym dla rozpatrywania sporów wynikających z funkcjonowania Portalu jest sąd właściwy dla
              siedziby pozwanego.
            </p>
            <p>
              <strong>Gorzyce 27.06.2024</strong>
            </p>
            <p>
              <a target="_blank" rel="noopener noreferrer"
                 href="https://s3.eu-central-1.amazonaws.com/secure-pliki/informacja_dla_konsumenta.pdf">Tpay -
                informacje dla konsumenta</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'regulations',
  bodyClass: 'regulations',
  metaInfo: function () {
    return {
      meta: [
        {
          name: 'robots',
          content: "noindex",
        },
        {
          name: 'googlebot-news',
          content: "nosnippet",
        },
        {
          name: 'googlebot',
          content: "noindex",
        },
      ],
    }
  },
}
</script>
<style scoped>
.features-4 .card {
  margin-top: 0;
  height: 265px;
}
</style>
