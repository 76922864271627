<template>
  <div
    class="section section-signup"
    style="
      background-image: url('img/bg11.jpg');
      background-size: cover;
      background-position: top center;
      min-height: 700px;
    "
  >
    <div class="container">
      <div class="row">
        <card class="card-signup" header-classes="text-center" color="orange">
          <template slot="header">
            <h3>Zostaw namiar do siebie a my skontaktujemy się z Tobą</h3>
          </template>
          <template>
            <form @submit.prevent="handleSubmit">
              <alert type="info" v-show="alert">
                <div class="alert-icon">
                  <i class="now-ui-icons ui-2_like"></i>
                </div>
                <strong>Dzięki!</strong> odezwiemy się...
              </alert>
              <div v-show="!alert">
                <div v-if="$v.form.firstName.$error" class="invalid-feedback d-block">
                  <span class="error" v-if="!$v.form.firstName.required">Pole jest wymagane</span>
                </div>
                <fg-input
                  class="no-border"
                  placeholder="Imię..."
                  v-model="form.firstName"
                  addon-left-icon="now-ui-icons users_circle-08"
                >
                </fg-input>
                <div v-if="$v.form.email.$error" class="invalid-feedback d-block">
                  <span class="error" v-if="!$v.form.email.required">Pole jest wymagane</span>
                  <span class="error" v-if="!$v.form.email.email">Podano niepoprawny e-mail</span>
                </div>
                <fg-input
                  class="no-border"
                  placeholder="Email"
                  v-model="form.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                >
                </fg-input>
                <div v-if="$v.form.phone.$error" class="invalid-feedback d-block">
                  <span class="error" v-if="!$v.form.firstName.required">Pole jest wymagane</span>
                </div>
                <fg-input
                  class="no-border"
                  placeholder="Telefon"
                  v-model="form.phone"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                >
                </fg-input>
                <div class="card-footer text-center">
                  <button type="neutral" round class="btn btn-round btn-neutral btn-lg">Wyślij</button>
                </div>
              </div>
            </form>
          </template>

          <br /><br />
        </card>
      </div>
      <br /><br />
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Button, Alert } from "@/components";
import { required, email } from "vuelidate/lib/validators";
import md5 from "js-md5";

export default {
  components: {
    Alert,
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },
      },
    };
  },
  data: function () {
    return {
      alert: false,
      form: {
        firstName: "",
        email: "",
        phone: "",
        code: "",
      },
    };
  },
  methods: {
    setMD5Sum: function () {
      this.form.code = md5(`${this.form.firstName}&${this.form.email}&${process.env.VUE_APP_EMAIL_SECRET_KEY}`);
    },
    handleSubmit(e) {
      this.submitted = true;
      this.setMD5Sum();

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.submitted = false;
        return;
      }
      this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "/email", this.form)
        .then((response) => {
          this.data = response.data;
          this.alert = true;
        })
        .catch(() => {
          this.submitted = true;
          this.alert = true;
        });
    },
  },
};
</script>
<style></style>
