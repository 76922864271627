<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax class="page-header-image" style="background-image: url('img/time.jpg')"> </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">O nas, czyli o e\timing!</h1>
            <h4>
              Za projektem stoją niesamowici ludzie z pasją do sportu z doświadczeniem w organizacji zawodów sportowych.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="text-center">
        <div class="features-3">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mr-auto ml-auto">
                <h2 class="title">Łatwiej. Sprytniej. Szybciej.</h2>
                <h4 class="description">
                  <b>Etiming</b> to niezawodny pomiar dla biegów, jazdy na rowerze, duathlonów czy triathlonów. Łączymy
                  najnowsze technologie z wysoką jakością obsługi, co pozwala nam zapewnić najlepsze wrażenia dla
                  uczestników i organizatorów. Nasze pomiary opierają się na uznanym na świecie systemie Raceresult, co
                  zapewnia niezawodność i precyzję.
                </h4>
              </div>
            </div>
            <div class="separator-line"></div>
            <div class="row">
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-success icon-circle">
                    <i class="now-ui-icons objects_globe"></i>
                  </div>
                  <h4 class="info-title">Pomiar czasu na zawodach</h4>
                  <p class="description"></p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-info icon-circle">
                    <i class="now-ui-icons education_atom"></i>
                  </div>
                  <h4 class="info-title">Sprzęt</h4>
                  <p class="description"></p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-primary icon-circle">
                    <i class="now-ui-icons tech_watch-time"></i>
                  </div>
                  <h4 class="info-title">Oprogramowanie</h4>
                  <p class="description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-content" data-background-color="black">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container">
                <img
                  class="img img-raised rounded img-comments rellax"
                  data-rellax-speed="1"
                  src="img/project17.jpg"
                  alt="miejsce"
                />
              </div>
            </div>
            <div class="col-md-6 ml-auto mr-auto">
              <div class="section-description">
                <h2 class="title">Miejsce działania</h2>
                <h7 class="category">Gdzie przebywamy i gdzie możemy dojechać?</h7>
                <h5 class="description">
                  Nasz zasięg działania obejmuje głównie masta: Tarnobrzeg, Stalowa Wola, Rzeszów, Lublin czy Kielce,
                  ale nie straszne nam wychylenie nosa poza ten obszar. Pochodzimy z małej miejsowości Gorzyce, która
                  słynie z biegania co pozwala nam na dogłębną znajomość rynku i potrzeb naszych klientów. Naszym celem
                  jest dostarczanie najnowocześniejszych rozwiązań i usług, aby spełnić oczekiwania naszych klientów.
                  Dzięki naszemu doświadczeniu i profesjonalizmowi jesteśmy w stanie zapewnić niezawodność i precyzję w
                  pomiarze czasu.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator-line separator-primary"></div>
      <div class="features-4">
        <div class="container">
          <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center">
              <h2 class="title">Jeszcze nie przekonany?</h2>
              <h4 class="description">
                Zespół <b>Etiming</b> może zapewnić wszystko co jest związane z pomiarem i organizacją imprez
                sportowych. Zapisy on-line, obsługa biura zawodów, kompleksowa organizacja zawodów. Możemy również
                zapewnić inne specjalnie przygotowane usługi, które sprawią że Wasze zawody będą na światowym poziomie.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg-101.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons emoticons_satisfied"></i></div>
                  <div class="description">
                    <h4 class="info-title">Zapisy internetowe</h4>
                    <p>Rejestracja na stronie internetowej wraz z listą zapisanych uczestników.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg-100.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons objects_spaceship"></i></div>
                  <div class="description">
                    <h4 class="info-title">Wyniki na żywo</h4>
                    <p>Lista z czasami dostępna na żywo.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg21.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons tech_laptop"></i></div>
                  <div class="description">
                    <h4 class="info-title">Markowy sprzęt</h4>
                    <p>Używamy markowego, jednego z najlepszych na świecie systemu do pomiaru czasu.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg-102.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons design-2_ruler-pencil"></i></div>
                  <div class="description">
                    <h4 class="info-title">Druk numerów</h4>
                    <p>
                      Możliwość wydruku numerów startowych wraz z możliwością wcześniejszej dostawy. Wydruk odbywa się
                      na woodoodpornym materiale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg27.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons objects_planet"></i></div>
                  <div class="description">
                    <h4 class="info-title">Obsługa informatyczna</h4>
                    <p>
                      Obsługujemy kompleksowo całe zawody sportowe pod względem informatycznym. Czyli zaczynając na
                      zapisach, kończąc na wynikach.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                data-background-color=""
                class="card card-background card-raised"
                style="background-image: url('img/bg-g5.jpg')"
              >
                <div class="info">
                  <div class="icon icon-white"><i class="now-ui-icons sport_user-run"></i></div>
                  <div class="description">
                    <h4 class="info-title">Publikacja wyników</h4>
                    <p>
                      Wyniki dostępne są na naszej stronie, natomiast w czasie trwania biegu, wyniki są dostarczane
                      organizatorom w formie papierowej.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox } from "@/components";
import { Select, Option } from "element-ui";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Etiming",
    // all titles will be injected into this template
    titleTemplate: "O nas | %s",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Nasz zasięg działania obejmuje głównie masta: Tarnobrzeg, Stalowa Wola, Rzeszów, Lublin czy Kielce, ale nie straszne nam wychylenie nosa poza ten obszar. Pochodzimy z małej miejsowości Gorzyce, która słynie z biegania co pozwala nam na dogłębną znajomość rynku i potrzeb naszych klientów. Naszym celem jest dostarczanie najnowocześniejszych rozwiązań i usług, aby spełnić oczekiwania naszych klientów. Dzięki naszemu doświadczeniu i profesjonalizmowi jesteśmy w stanie zapewnić niezawodność i precyzję w pomiarze czasu.",
      },
    ],
  },
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: "",
      },
    };
  },
};
</script>
<style scoped>
.features-4 .card {
  margin-top: 0 !important;
  height: 265px;
}
</style>
