<template>
  <div class="wrapper">
    <div class="page-header header-filter" filter-color="black">
      <parallax class="page-header-image" style="background-image: url('img/background.jpg')"> </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="img/logo.svg" alt="" />
        </div>
      </div>
    </div>

    <nucleo-icons-section></nucleo-icons-section>
    <div class="section" >
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12">
            <h2 class="title">Nasz sprzęt</h2>
            <h5 class="description">
              Jako firma, która zajmuje się pomiarem czasu, dokładamy wszelkich starań, aby nasze usługi były na
              najwyższym poziomie. Dlatego też postawiliśmy na jakość i zdecydowaliśmy się na używanie najlepszego
              sprzętu do pomiaru czasu na świecie. Niemiecka firma Race Result jest liderem w produkcji urządzeń do
              pomiaru czasu. Ich sprzęt jest używany na największych i najbardziej prestiżowych imprezach sportowych na
              świecie. Dzięki temu, że korzystamy z tego sprzętu, możemy zagwarantować najwyższą jakość i precyzję
              pomiaru czasu. Nie tylko używamy najlepszego sprzętu, ale również stale podnosimy swoje kwalifikacje i
              dostosowujemy się do nowych technologii, aby zapewnić naszym klientom usługi na najwyższym poziomie.
              Wierzymy, że jakość jest kluczem do sukcesu i dlatego tak ważna jest dla nas.
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="separator-line separator-primary"></div>
    <signup-form></signup-form>
    <download-section></download-section>
  </div>
</template>
<script>
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
  },
};
</script>
<style scoped>

.category-absolute a.innov8tech {
  color: #ccc;
  background-color: #212121;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
}

.category-absolute a.innov8tech span {
  font-style: italic;
}

@media screen and (max-width: 991px) {
  .category-absolute {
    display: none;
  }
}


</style>
