<template>
  <navbar
      position="fixed"
      type="danger"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto"
  >
    <template slot-scope="{ toggle, isToggled }">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        etiming
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item" v-if="this.event.slug && !this.event.is_ended">
        <router-link :to="{ name: 'register', params: { name: this.event.slug }}"
                     class="nav-link btn btn-danger"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>&nbsp;Zapisz się</p>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.event.twitter">
        <a
            class="nav-link"
            rel="tooltip"
            title="Follow us on Twitter"
            data-placement="bottom"
            :href="'https://twitter.com/' + this.event.twitter"
            target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item" v-if="this.event.facebook">
        <a
            class="nav-link"
            rel="tooltip"
            title="Like us on Facebook"
            data-placement="bottom"
            :href="'https://facebook.com/' + this.event.facebook"
            target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item" v-if="this.event.instagram">
        <a
            class="nav-link"
            rel="tooltip"
            title="Follow us on Instagram"
            data-placement="bottom"
            :href="'https://instagram.com/' + this.event.instagram"
            target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import {DropDown, Navbar, NavbarToggleButton, NavLink} from '@/components';
import {Popover} from 'element-ui';
import NButton from "../components/Button";

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  mounted: function () {
    this.$root.$on('event', (event) => {
      this.event = event;
    });
  },
  data: function () {
    return {
      event: {}
    }
  },
  components: {
    NButton,
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
