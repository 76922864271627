<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Pomiar czasu</h2>
          <h5 class="description">
            Jako organizator zawodów sportowych z pewnością wiesz, że precyzyjny pomiar czasu jest kluczowy dla udanej
            imprezy. Dlatego też oferujemy Ci nasze usługi, które pozwolą Ci na skupienie się na innych aspektach
            organizacji zawodów, a my zajmiemy się pomiarem czasu. <br/><br/>Nasza oferta obejmuje szereg jakościowych rozwiązań,
            od najnowocześniejszych urządzeń do pomiaru czasu, po oprogramowanie, które pozwoli Ci na przeprowadzanie
            analizy i prezentację wyników. Jesteśmy elastyczni i otwarci na pełną współpracę, co oznacza, że nasze
            rozwiązania dopasujemy do Twoich potrzeb i oczekiwań. <br/><br/>Chcemy, aby Twoje zawody były niezapomniane i na
            najwyższym poziomie, dlatego też zadbamy o to, aby pomiar czasu był niezawodny i precyzyjny. Skontaktuj się
            z nami, aby poznać szczegóły naszej oferty i zorganizować najlepsze zawody sportowe na świecie.
          </h5>
          <a href="/" class="btn btn-primary btn-round btn-lg">Nasza oferta</a>&nbsp;
          <a href="/about-us" class="btn btn-primary btn-simple btn-round btn-lg">O nas</a>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="icons-container">
            <i class="now-ui-icons ui-1_send"></i>
            <i class="now-ui-icons ui-2_like"></i>
            <i class="now-ui-icons transportation_air-baloon"></i>
            <i class="now-ui-icons text_bold"></i>
            <i class="now-ui-icons tech_headphones"></i>
            <i class="now-ui-icons emoticons_satisfied"></i>
            <i class="now-ui-icons shopping_cart-simple"></i>
            <i class="now-ui-icons objects_spaceship"></i>
            <i class="now-ui-icons media-2_note-03"></i>
            <i class="now-ui-icons ui-2_favourite-28"></i>
            <i class="now-ui-icons design_palette"></i>
            <i class="now-ui-icons clothes_tie-bow"></i>
            <i class="now-ui-icons location_pin"></i>
            <i class="now-ui-icons objects_key-25"></i>
            <i class="now-ui-icons travel_istanbul"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
