<template>
  <footer
      class="footer"
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link to="/about-us">
              O nas
            </router-link>
          </li>
          <li>
            <router-link to="/terms">
              Polityka prywatności
            </router-link>
          </li>
          <li>
            <router-link to="/regulations">
              Regulamin
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, created by
        <a href="https:///www.etiming.pl" target="_blank" rel="noopener">etiming</a> for the ❤️ of results.
      </div>
    </div>
  </footer>
</template>
<script>
import NavLink from "../components/Navbar/NavLink";

export default {
  components: {NavLink},
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
