<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax class="page-header-image" style="background-image: url('img/bg-g5.jpg')"> </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Masz pytania?</h1>
            <h4>Nie gryziemy, - skontaktuj sie z nami</h4>
            <a href="https://www.facebook.com/etimingPL" class="btn btn-round btn-icon">
              <i class="fab fa-facebook"></i
            ></a>
            <a href="https://www.twitter.com/etimingPL" class="btn btn-info btn-round btn-icon">
              <i class="fab fa-twitter"></i
            ></a>
            <a href="https://www.instagram.com/etimingPL" class="btn btn-danger btn-round btn-icon">
              <i class="fab fa-instagram"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-5 ml-auto mr-auto">
              <h2 class="title">Skontaktuj się z nami</h2>
              <p class="description">
                Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące naszych usług lub produktów, zachęcamy Cię do
                skontaktowania się z nami. Jesteśmy tutaj, aby pomóc i odpowiedzieć na wszystkie Twoje pytania jak
                najszybciej się da. <br /><br />Chętnie udzielimy Ci odpowiedzi na wszystkie Twoje pytania. Możesz
                skontaktować się z nami przez e-mail, telefon lub poprostu zostaw nam kontakt do siebie na naszej
                stronie internetowej a my skontaktujemy się z Tobą. <br /><br />Jeśli masz jakieś pytania dotyczące
                pomiaru czasu, zawsze jesteśmy gotowi, aby pomóc. Kontaktując się z nami, możesz być pewien, że
                otrzymasz fachową i rzetelną odpowiedź. Nie wahaj się i skontaktuj się z nami już dziś, a my postaramy
                się rozwiązać wszystkie Twoje wątpliwości jak najszybciej się da.
                <br />
                <br />
              </p>
            </div>
            <div class="col-md-5 ml-auto mr-auto">
              <info-section type="primary" class="mt-5" icon="now-ui-icons location_pin">
                <h4 class="info-title">Znajdź nas w biurze</h4>
                <p>
                  <b>Innov8TECH</b> <br />
                  Zakole 2/48, 39-432 Gorzyce <br />
                  <a href="mailto:contact@etiming.pl">contact@etiming.pl</a>
                </p>
              </info-section>

              <info-section type="primary" icon="now-ui-icons tech_mobile">
                <h4 class="info-title">Zadzwoń do nas</h4>
                <p>
                  <b>+48 663-202-929</b>
                  <br />
                  Pon - Pią, 9:00-20:00
                </p>
              </info-section>

              <info-section type="primary" icon="business_briefcase-24 now-ui-icons">
                <h4 class="info-title">Informacje o firmie</h4>
                <p>
                  Innov8TECH <br />
                  VAT · <b>867-208-14-92</b> <br />
                  REGON · 382835935 <br />
                  IBAN · 04 2490 0005 0000 4530 4487 3374 · Alior BANK
                </p>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br /><br /><br /><br />
    <div class="separator-line separator-primary"></div>
    <signup-form></signup-form>
  </div>
</template>
<script>
import SignupForm from "./components/SignupForm";
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  metaInfo: {
    title: "Etiming",
    titleTemplate: "Kontakt | %s",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Masz pytania? Nie gryziemy, - skontaktuj sie z nami. Nie wahaj się, a my postaramy się rozwiązać wszystkie Twoje wątpliwości jak najszybciej się da.",
      },
    ],
  },
  name: "contact-us",
  bodyClass: "profile-page",
  components: {
    SignupForm,
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
};
</script>
<style scoped>
.page-header:after {
  background-color: black;
  opacity: 0.4;
}
</style>
