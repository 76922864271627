<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <tabs
          slot="raw-content"
          centered
          tab-content-classes="tab-content-padding"
        >
          <tab-pane id="event">
            <template slot="label"
              ><i class="now-ui-icons sport_user-run"></i> O
              wydarzeniu</template
            >
            <p v-html="event.description"></p>
          </tab-pane>
          <tab-pane id="list" v-if="event && !event.is_ended">
            <template slot="label"
              ><i class="now-ui-icons design_bullet-list-67"></i> Lista
              zapisanych</template
            >
            <div class="col-12">
              <el-table class="table-striped" :data="members" v-if="event.id">
                <el-table-column
                  type="index"
                  :index="indexMethod"
                ></el-table-column>
                <el-table-column min-width="50" prop="country">
                  <div slot-scope="props">
                    <country-flag :country="props.row.country" size="small" />
                  </div>
                </el-table-column>
                <el-table-column
                  min-width="150"
                  prop="first_name"
                  label="Imię"
                ></el-table-column>
                <el-table-column
                  min-width="150"
                  prop="last_name"
                  label="Nazwisko"
                  sortable
                ></el-table-column>
                <el-table-column
                  min-width="150"
                  prop="club"
                  label="Klub"
                  sortable
                ></el-table-column>
                <el-table-column
                  min-width="150"
                  prop="city"
                  label="Miasto"
                  sortable
                ></el-table-column>
                <el-table-column
                  v-if="this.event.distances.length > 0"
                  min-width="150"
                  prop="distance_name"
                  label="Dystans"
                  sortable
                  :filters="
                    this.event.distances.map(({ name }) => ({
                      text: name,
                      value: name,
                    }))
                  "
                  :filter-method="filterDistance"
                ></el-table-column>
                <el-table-column
                  min-width="100"
                  label="Opłata"
                  sortable
                  align="center"
                  header-align="center"
                  :filters="[
                    { text: 'Opłacony płatnościami', value: 'paid' },
                    { text: 'Opłacony gotówką', value: 'cash' },
                    { text: 'Nie potwierdzony', value: 'non_confirmed' },
                    { text: 'Zwolniony', value: 'free' },
                  ]"
                  :filter-method="filterTag"
                  filter-placement="bottom-end"
                  v-if="event.payment != 0"
                >
                  <div slot-scope="props">
                    <form
                      :action="paymentUrl()"
                      method="post"
                      v-if="event.integrator > 0 && ['open', 'full'].includes(event.status) && props.row.status_payment === 'non_confirmed'"
                    >
                      <input type="hidden" name="crc" v-model="props.row.id" />
                      <button class="btn btn-primary">Opłać</button>
                    </form>
                    <span
                      v-else-if="
                        ['free', 'paid', 'cash'].includes(
                          props.row.status_payment
                        )
                      ">Potwierdzony</span>
                    <button class="btn btn-primary" @click="modals.payment=true;setPaymentData(props.row)" v-else-if="!event.tpay">Opłać</button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </tab-pane>
          <tab-pane id="regulations" v-if="event && event.regulations">
            <template slot="label"
              ><i class="now-ui-icons files_paper"></i> Regulamin</template
            >
            <p v-html="event.regulations"></p>
          </tab-pane>
        </tabs>
      </div>
    </div>
    <modal :show.sync="modals.payment" headerClasses="justify-content-center">
    <h4 slot="header" class="title title-up">Opłata za bieg</h4>
    <p v-html="renderLocalPayment()"></p>
    <template slot="footer">
      <n-button type="danger" @click.native="modals.payment = false">Zamknij</n-button>
    </template>
  </modal>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { Checkbox, Modal, TabPane, Tabs } from "@/components";
import NCheckbox from "../../components/Inputs/Checkbox";
import md5 from "js-md5";
import NButton from "../../components/Button";
import { saveAs } from "file-saver";
import CountryFlag from "vue-country-flag";

var moment = require("moment");

export default {
  components: {
    Modal,
    NButton,
    NCheckbox,
    Tabs,
    TabPane,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    CountryFlag,
  },
  props: {
    event: {},
    members: {},
  },
  methods: {
    renderLocalPayment: function() {
      return this.event.local_payment.replace("{{data_transfer}}", this.modals.data_transfer)
    },
    setPaymentData: function(row) {
      this.modals.data_transfer = `${row.distance_name} ${row.first_name} ${row.last_name} ${row.city}`
    },
    indexMethod: function (index) {
      return index + 1 + ".";
    },
    download: function (url, filename) {
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          saveAs(response.data, filename);
        })
        .catch(function (err) {
          console.log(err.message);
        });
    },
    filterTag: function (value, row) {
      return row.status_payment === value;
    },
    filterDistance: function (value, row) {
      return row.distance_name === value;
    },
    getMD5Sum: function (crc, amount) {
      return md5(
        `${this.clientId()}&${amount}&${crc}&${process.env.VUE_APP_TPAY_CODE}`
      );
    },
    paymentUrl: function () {
      return `${process.env.VUE_APP_BACKEND_URL}/payment`;
    },
    etimingUrl: function () {
      return `${process.env.VUE_APP_CLIENT_URL}/event/${this.event.slug}`;
    },
    clientId: function () {
      return `${process.env.VUE_APP_TPAY_CLIENT}`;
    },
  },
  data: function () {
    return {
      modals: {
        data_transfer: "",
        payment: false,
      },
      today: moment().format(),
    };
  },
};
</script>
<style>
.table-striped .tab-content.tab-content-padding {
  padding: 20px;
}

.table-striped {
  font-size: 12px;
}

.el-table td {
  padding: 0;
}

.el-table .cell {
  white-space: normal;
  word-break: normal !important;
}

.results {
  margin-bottom: 100px;
}

.el-table .woman {
  color: black;
}

.el-table .result-column {
  font-weight: 600;
}
</style>
