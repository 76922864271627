<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax class="page-header-image"
                style="background-image: url('img/bg24.jpg')">
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Polityka prywatności</h1>
            <h4>etiming.pl</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ">
            <p>
              ETIMING.PL zajmuje się profesjonalną obsługą zawodów sportowych w zakresie
              zbierania
              zapisów, pomiaru czasu, publikowania wyników w Internecie na żywo, oraz po zawodach. W
              związku z
              tym prowadzimy serwis na którym przetwarzamy dane osób które startują w zawodach sportowych.
            </p>
            <h2>Administrator danych osobowych ETIMING.PL</h2>
            <p>
              Tomasz Dziorek, przedsiębiorca prowadzący działalność gospodarczą pod nazwą INNOV8TECH Tomasz Dziorek
              wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej
              prowadzonej przez Ministra Rozwoju i Finansów, NIP 8672081492 , REGON 382835935, ul. Zakole 2/48,
              39-432 Gorzyce.
            </p>
            <h2>Portal Zapisów</h2>
            <p>
              Miejsce gdzie użytkownicy mogą zgłosić się do zawodów i gdzie przechowywane są ich wyniki.
              Na
              potrzeby portalu zapisów zawodnik podczas rejestracji musi podać następujące dane osobowe:
            </p>
            <p>
              <ul>
                <li>Imię i nazwisko</li>
                <li>Płeć</li>
                <li>Rok urodzenia</li>
                <li>Miasto</li>
                <li>Klub</li>
                <li>Adres E-mail</li>
                <li>Kraj w jakim mieszka</li>
                <li>Narodowość</li>
                <li>Numer telefonu komórkowego</li>
              </ul>
            </p>
            <p>
              Poza tymi danymi w portalu przechowujemy historię startów i zgłoszeń użytkownika, dzięki
              czemu
              może
              on analizować swoje postępy i pilnować czy dokonał już wszelkich formalności związanych ze
              startami
              w wielu imprezach. Dane te są publicznie dostępne, jeśli nie chcesz ich pokazywać innym
              osobom
              możesz to zastrzec.

              W każdej chwili użytkownik może edytować swoje dane, których edycja jest możliwa tylko i
              wyłącznie
              przez uprawnionego pracownika firmy INNOV8TECH. Można jej dokonać pisząc prośbę na
              adres
              e-mail: contact@innov8tech.pl. Pamiętaj, że konta nie można udostępniać, odsprzedawać czy
              wykorzystywać do zapisu za jego pomocą osób trzecich.

              Rejestrując się do portalu użytkownik akceptuje regulamin i wyraża, bądź nie, zgodę na
              otrzymywanie
              sporadycznych informacji marketingowych o przyszłych imprezach sportowych.

              Zawodnik podczas zgłaszania się do zawodów w serwisie ETIMING.PL, dobrowolnie
              udostępnia
              swoje dane organizatorowi akceptując jego odrębny regulamin i politykę prywatności.
              Regulamin
              ETIMING.PL i ta Polityka prywatności nie może być podstawą roszczeń uczestnika
              wydarzenia w
              stosunku do organizatora

              Pliki cookies są w portalu wykorzystywane do utrzymywania sesji użytkownika. <b>Nie
              profilujemy
              i
              nie
              udostępniamy informacji mogących służyć profilowaniu naszych użytkowników podmiotom
              trzecim.</b>
            </p>
            <h2>Portal Organizatora</h2>
            <p>
              Jest to system do zarządzania zgłoszeniami w ramach portalu zapisów. Każdy organizator
              imprezy
              może
              bez opłat założyć sobie zapisy w naszym systemie i zbierać za jego pomogą zgłoszenia osób
              które
              wyrażą chęć udziału w jego imprezie. Aby spełnić wszystkie formalności podczas rejestracji
              organizatora zbieramy następujące dane:
            </p>
            <p>
              <ul>
                <li>Nazwę podmiotu organizatora</li>
                <li>Nazwisko i imię przedstawiciela organizatora</li>
                <li>Adres siedziby organizatora</li>
                <li>Dane kontaktowe inspektora danych osobowych organizatora jeśli taki jest wyznaczony</li>
                <li>E-mail kontaktowy administratora</li>
                <li>Telefon kontaktowy administratora</li>
              </ul>
            </p>
            <p>
              Organizator w ramach swojego konta może prowadzić zapisy na wiele imprez. Uczestnik imprezy,
              dokonując rejestracji na wydarzenie organizatora zobowiązuje ETIMING.PL do
              przekazania
              danych osobowych organizatorowi.
            </p>
            <h2>Oprogramowanie pomiaru czasu</h2>
            <p>
              Jest to system, który rejestruje sygnały z transponderów RFID, łączy je z danymi zawodnika i
              oblicza
              wyniki zawodów. Organizator zawodów przekazuje dane osobowe zawodników, na podstawie umowy
              powierzenia, potrzebne do poprawnego wygenerowania wyników w szczególności:
            </p>
            <p>
              <ul>
                <li>Imię</li>
                <li>Nazwisko</li>
                <li>Rok urodzenia</li>
                <li>Miejscowość</li>
                <li>Klub</li>
                <li>Numer telefonu komórkowego</li>
              </ul>
            </p>
            <p>
              Po zawodach dane te (prócz numeru telefonu) są publikowane w serwisach ETIMING.PL i
              usuwane
              z oprogramowania scoeringowego, po uprzedniej archiwizacji danych na bezpiecznym
              nośniku.
            </p>
            <h2>Usługi zewnętrzne</h2>
            <p>
              Korzystamy z usług podmiotów zewnętrznych, którym mogą być przekazywane Twoje dane.
              Poniżej znajduje się lista możliwych odbiorców Twoich danych:
            </p>
            <p>
              <ul>
                <li>podmiot realizujący dostawę towarów</li>
                <li>dostawca płatnośc: TPay.com, Cinkciarz.pl</li>
                <li>hostingodawca</li>
                <li>biuro rachunkowe</li>
                <li>podmiot zapewniający usługi marketingowe</li>
                <li>odpowiednie organy publiczne w zakresie, w jakim jesteśmy zobowiązani do udostępnienia im danych
                </li>
              </ul>
            </p>
            <h2>Wyniki</h2>
            <p>
              Po zawodach wyniki są publikowane na stronie etiming.pl a przede wszystkim w serwisie
              Wynik
              ETIMING.PL. W wynikach znajdują się następujące dane osobowe:
            </p>
            <p>
              <ul>
                <li>Imię</li>
                <li>Nazwisko</li>
                <li>Kategoria wiekowa</li>
                <li>Miejscowość</li>
                <li>Klub</li>
                <li>Kraj</li>
              </ul>
            </p>
            <p>
              Dane te są dostępne publicznie i dostęp do nich nie jest w żaden sposób ograniczony.
              Jednakże
              zawodnik można je zastrzec.
            </p>
            <h2>Bezpieczeństwo</h2>
            <p>
              ETIMING.PL dokłada wszelkich starań aby Państwa dane były bezpieczne. Nasz serwisy
              jest
              ponadto zabezpieczony za pomocą bezpiecznych kanałów wymiany informacji przy zastosowaniu
              technologii SSL. Dostęp do danych osobowych zawodników ograniczony jest tylko do pracowników
              którzy
              taki dostęp muszą posiadać. Dane z serwisów są regularnie archiwizowane, sprawdzane i
              konserwowane.
              Posiadamy też nowoczesne rozwiązania zabezpieczające przed atakami z zewnątrz i
              ograniczające do
              minimum ryzyko penetracji systemu przez osoby nieupoważnione a także ochronę przed atakami
              DoS
              które
              mogłyby doprowadzić do całkowitego sparaliżowania naszych serwisów.

              Prywatność naszych użytkowników jest dla nas niezwykle ważna, od zawsze dokładamy wszelkich
              starań
              aby korzystanie z naszych usług było łatwe a zarazem bezpieczne. Pomimo to, w razie
              jakichkolwiek
              uwag prosimy o kontakt z naszym biurem pod adresem: contact@innov8tech.pl lub pod numerem
              telefonu:
              +48 663 202 929</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'terms',
  bodyClass: 'terms',
  metaInfo: function () {
    return {
      meta: [
        {
          name: 'robots',
          content: "noindex",
        },
        {
          name: 'googlebot-news',
          content: "nosnippet",
        },
        {
          name: 'googlebot',
          content: "noindex",
        },
      ],
    }
  },
}
</script>
<style scoped>
.features-4 .card {
  margin-top: 0;
  height: 265px;
}
</style>
