<template>
    <div class="wrapper">
        <n-button @click="filterClick(tag)" :class="{'btn-primary':tag.id == selected}" size="sm"
                  v-for="tag in tags" v-bind:key="tag.id">
            {{ tag.name }}
        </n-button>
        <n-button @click="filterClick(null)" :class="{'btn-primary':undefined == selected}" size="sm">
            Wszystkie
        </n-button>
    </div>
</template>
<script>

    import NButton from "./Button";

    export default {
        name: 'events-filter',
        components: {NButton},
        props: {
            color: String,
            type: String
        },
        data: function () {
            return {
                tags: [],
                selected: undefined,
            }
        },
        methods: {
            filterClick(tag) {
                if (!tag) {
                    this.selected = undefined;
                    this.$emit('setTag', null);
                    return
                }
                this.$emit('setTag', tag.id);
                this.selected = tag.id
            },
        },
        mounted: function () {
            this.$http.get(process.env.VUE_APP_BACKEND_URL + "/tags/").then(response => {
                this.tags = response.data
            });
        },
    };
</script>
<style></style>
