import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {Button} from '@/components'
import Vuelidate from 'vuelidate'
import App from './App.vue';
import VueMeta from 'vue-meta'
import axios from 'axios';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import CountryFlag from 'vue-country-flag'


const moment = require('moment');
const md5 = require('js-md5');
require('moment/locale/pl');

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.use(ElementUI);
Vue.use(Vuelidate);
Vue.use(NowUiKit);
Vue.use(require('vue-moment'), {
  moment
});
Vue.use(VueMeta);
Vue.component(Button.name, Button);
Vue.component('country-flag', CountryFlag)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
