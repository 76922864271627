<template>
    <div class="wrapper">

        <div class="page-header header-filter">
            <div class="page-header-image" style="background-image: url('img/bg27.jpg')"></div>
            <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 ml-auto mr-auto text-center">
                            <h1 class="title"> Numerki startowe!</h1>
                            <h5 class="description">Specjalizujemy się w pomiarach czasu na zawodach sportowych.
                                Dzięki połączeniu najnowszej technologii, zaawansowanego oprogramowania oraz nabytego
                                doświadczenia, jesteśmy w stanie świadczyć usługi na najwyższym poziomie.</h5>
                        </div>
                        <div class="col-md-10 ml-auto mr-auto">
                            <div class="card card-raised card-form-horizontal card-plain" data-background-color>
                                <div class="card-body">
                                    <form method="" action="">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Imię"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Numer telefonu"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Miejscowość"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <n-button type="primary" round block>Wyślij</n-button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="features-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <h2 class="title">Working is a pleasure</h2>
                        <div class="info info-horizontal">
                            <div class="icon icon-primary icon-circle">
                                <i class="now-ui-icons location_world"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Hundreds of Components</h4>
                                <p>The moment you use Now UI Kit, you know you’ve never felt anything like it. With a
                                    single use lets you do more than ever before. </p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-primary icon-circle">
                                <i class="now-ui-icons sport_user-run"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Easy to Use</h4>
                                <p>Divide details about your product or agency work into parts. Write a few lines about
                                    each one. A paragraph describing a feature.</p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-primary icon-circle">
                                <i class="now-ui-icons ui-2_time-alarm"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Fast Prototyping</h4>
                                <p>Divide details about your product or agency work into parts. Write a few lines about
                                    each one. A paragraph describing a feature.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="tablet-container">
                            <img v-lazy="'img/ipad2-inverted.png'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="pricing-5 section-pricing-5 section-image" id="pricing-5"
             style="background-image: url('img/bg31.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <h2 class="title">Wybierz co mamy zmierzyć</h2>
                        <tabs v-model="pricing5Tab" pills type="primary" no-content-space>
                            <tab-pane label="Bieg"></tab-pane>
                            <tab-pane label="Rower"></tab-pane>
                            <!--                                <tab-pane label="Triathlon"></tab-pane>-->
                        </tabs>
                        <p class="description">You have Free Unlimited Updates and Premium Support on each package.
                            You
                            also have 20 days to request a refund if you're not happy with your purchase.</p>
                    </div>
                    <div class="col-md-8 ml-auto mr-auto">
                        <div class="tab-content tab-space">
                            <div v-if="pricing5Tab === 'Rower'" class="tab-pane active" id="personal">
                                <div class="row">
                                    <div class="col-md-4">
                                        <card type="pricing" raised>
                                            <h6 class="category">Plus</h6>
                                            <h1 class="card-title">
                                                2199
                                                <small>PLN</small>
                                            </h1>
                                            <ul>
                                                <li>
                                                    <b>1</b> punkt*
                                                </li>
                                                <li>
                                                    <b>Darmowe</b> zapisy
                                                </li>
                                                <li>
                                                    <b>Nielimitowani</b> użytkownicy
                                                </li>
                                                <li>
                                                    <b>No time</b> tracking
                                                </li>
                                            </ul>
                                            <router-link to="contact" class="btn btn-primary btn-round">Napisz do
                                                nas
                                            </router-link>
                                        </card>
                                    </div>
                                    <div class="col-md-4">
                                        <card type="pricing" plain>
                                            <h6 class="category">Maximum</h6>
                                            <h1 class="card-title">
                                                2499
                                                <small>PLN</small>
                                            </h1>
                                            <ul>
                                                <li>
                                                    <b>25</b> Projects
                                                </li>
                                                <li>
                                                    <b>15GB</b> Storage
                                                </li>
                                                <li>
                                                    <b>Unlimited</b> users
                                                </li>
                                                <li>
                                                    <b>No time</b> tracking
                                                </li>
                                            </ul>
                                            <router-link to="contact" class="btn btn-primary btn-round">Napisz do
                                                nas
                                            </router-link>
                                        </card>
                                    </div>
                                    <div class="col-md-4">
                                        <card type="pricing" plain>
                                            <h6 class="category">Maximum</h6>
                                            <h1 class="card-title">
                                                2499
                                                <small>PLN</small>
                                            </h1>
                                            <ul>
                                                <li>
                                                    <b>25</b> Projects
                                                </li>
                                                <li>
                                                    <b>15GB</b> Storage
                                                </li>
                                                <li>
                                                    <b>Unlimited</b> users
                                                </li>
                                                <li>
                                                    <b>No time</b> tracking
                                                </li>
                                            </ul>
                                            <router-link to="contact" class="btn btn-primary btn-round">Napisz do
                                                nas
                                            </router-link>
                                        </card>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="tab-pane active" id="commercial">
                                <div class="row">
                                    <div class="col-md-6">
                                        <card type="pricing" raised>

                                            <h6 class="category">Standard</h6>
                                            <h1 class="card-title">
                                                1999
                                                <small>zł</small>
                                            </h1>
                                            <ul>
                                                <li>
                                                    <b>5</b> Projects
                                                </li>
                                                <li>
                                                    <b>2GB</b> Storage
                                                </li>
                                                <li>
                                                    <b>Unlimited</b> users
                                                </li>
                                                <li>
                                                    <b>No time</b> tracking
                                                </li>
                                            </ul>
                                            <router-link to="contact" class="btn btn-primary btn-round">Napisz do
                                                nas
                                            </router-link>

                                        </card>
                                    </div>
                                    <div class="col-md-6">
                                        <card type="pricing" plain>
                                            <h6 class="category">Premium</h6>
                                            <h1 class="card-title">
                                                2299
                                                <small>zł</small>
                                            </h1>
                                            <ul>
                                                <li>
                                                    <b>7</b> Projects
                                                </li>
                                                <li>
                                                    <b>3GB</b> Storage
                                                </li>
                                                <li>
                                                    <b>Unlimited</b> users
                                                </li>
                                                <li>
                                                    <b>No time</b> tracking
                                                </li>
                                            </ul>
                                            <router-link to="contact" class="btn btn-primary btn-round">Napisz do
                                                nas
                                            </router-link>
                                        </card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="projects-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h2 class="title">We have many advantages</h2>
                        <h4 class="description">This is the paragraph where you can write more details about
                            your projects. Keep you user engaged by providing meaningful information.</h4>
                        <div class="section-space"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 ml-auto mt-5">
                        <info-section type="warning"
                                      icon="now-ui-icons users_single-02"
                                      title="Work With Any Team"
                                      description="Whether it’s for work or even the next family vacation, Trello helps your team."
                        />

                        <info-section type="warning"
                                      icon="now-ui-icons business_chart-bar-32"
                                      title="A Productivity Platform"
                                      description="Integrate the apps your team already uses directly into your workflow."
                        />

                        <info-section type="warning"
                                      icon="now-ui-icons arrows-1_refresh-69"
                                      title="Always In Sync"
                                      description="No matter where you are, Trello stays in sync across all of your devices."
                        />

                    </div>
                    <div class="col-md-5 mr-auto">
                        <card type="background" raised class="card-background-product"
                              style="background-image: url('img/project9.jpg')">
                            <h2 class="card-title">Trello lets you work.</h2>
                            <p class="card-description text-white">
                                Trello’s boards, lists, and cards enable you to organize and prioritize your
                                projects in a fun, flexible and rewarding way.
                            </p>
                            <badge type="neutral">Trello</badge>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="separator-line separator-primary"></div>

    </div>
</template>
<script>
    import {Card, InfoSection, Badge, Button, FormGroupInput, Tabs, TabPane} from '@/components';
    import NavLink from "../components/Navbar/NavLink";

    export default {
        components: {
            NavLink,
            Card,
            InfoSection,
            Badge,
            Tabs,
            TabPane,
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
        },
        data() {
            return {
                pricing5Tab: 'Expensive'
            }
        }
    }
</script>
<style scoped>

</style>
