<template>
    <div class="wrapper">
        <div class="page-header header-filter" filter-color="orange">
            <parallax
                    class="page-header-image"
                    style="background-image:url('/img/background.jpg'); background-position: bottom center;"
            >
            </parallax>

            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h1 class="title">Zawody sportowe</h1>
                        <h4>lista dostępnych wydarzeń</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
<!--                <div class="row">-->
<!--                    <div class="col-md-12 text-center">-->
<!--                        <events-filter v-on:setTag="setTag"></events-filter>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="row">
                    <div class="col-md-4 px-1" v-for="event in filteredEvents" v-on:click="toEvent(event.slug)">
                        <card class="event" type="background"
                              v-bind:style="{ backgroundImage: 'url(' + event.cover + ')' }">
                            <div class="stats-link pull-right" v-if="!event.is_ended">
                                <i class="now-ui-icons sport_user-run"></i>Limit: {{ event.limit }}
                            </div>
                            <div class="stats-link pull-right" v-if="event.is_ended">
                                <i class="now-ui-icons sport_user-run"></i>Zapisanych: {{ event.members_count }}
                            </div>
                            <div class="card-title text-left">
                                <h3>{{ event.name }}</h3>
                            </div>
                            <div class="card-footer events text-left">
                                <div class="stats">
                                    <span>
                                    <i class="now-ui-icons location_pin"></i>{{ event.city }}
                                    </span>
                                    <span v-if="!event.is_ended">
                                    <i class="now-ui-icons tech_watch-time"></i>za {{ event.event_start | moment("from", "now", true) }}
                                    </span>
                                    <span v-if="event.is_ended">
                                    <i class="now-ui-icons tech_watch-time"></i>{{ event.event_start | moment("YYYY-MM-DD") }}
                                    </span><span v-if="event.status === 'not_started'">(zapisy wkrótce)</span>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Card from "../components/Cards/Card";
    import EventsFilter from "../components/EventsFilter";
    var moment = require('moment');

    export default {
        metaInfo: {
            title: "Etiming",
            titleTemplate: "Wydarzenia | %s",
            meta: [{ vmid: 'description', name: "description", content: "Lista wydarzeń sportowych." }],
        },
        name: "Events",
        components: {Card, EventsFilter},
        bodyClass: "profile-page",
        computed: {},
        mounted: function () {
            let data = {
                params: {
                    'event_start__gte': moment().format(),
                    'ordering': 'event_start'
                }
            }
            this.$http.get(process.env.VUE_APP_BACKEND_URL + "/events/", data).then(response => {
                this.events = response.data
            });
            this.$http.get(process.env.VUE_APP_BACKEND_URL + "/tags/").then(response => {
                this.tags = response.data
            });
        },
        computed: {
            filteredEvents: function () {
                return this.events.filter(({tag}) => {
                    if (!this.tag) {
                        return true;
                    }
                    return tag == this.tag
                })
            }
        },
        methods: {
            setTag(value) {
                this.tag = value;
            },
            toEvent(slug) {
                this.$router.push({name: "event", params: {name: slug}})
            }
        },
        data: function () {
            return {
                tag: null,
                events: [],
                tags: [],
                today: moment().format(),
            };
        }
    };
</script>
<style scoped>
    .event {
        font-size: 11px;
        color: white;
        cursor: pointer;
    }

    .page-header {
        min-height: 400px !important
    }
    .card-footer.events {
        position: absolute;
        bottom: 40px;
    }
</style>
