<template>
    <div class="wrapper">

        <div class="page-header header-filter">
            <div class="page-header-image" style="background-image: url('img/bg3.jpg')"></div>
            <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 ml-auto mr-auto text-center">
                            <h1 class="title"> Pomiar czasu!</h1>
                            <h5 class="description">Specjalizujemy się w pomiarach czasu na zawodach sportowych.
                                Dzięki połączeniu najnowszej technologii, zaawansowanego oprogramowania oraz nabytego
                                doświadczenia, jesteśmy w stanie świadczyć usługi na najwyższym poziomie.</h5>
                        </div>
                        <div class="col-md-10 ml-auto mr-auto">
                            <div class="card card-raised card-form-horizontal card-plain" data-background-color>
                                <div class="card-body">
                                    <form method="" action="">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Imię"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Numer telefonu"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input value="" placeholder="Miejscowość"></fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <n-button type="primary" round block>Wyślij</n-button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="features-6">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Working is a pleasure</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your
                            product. Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="info info-horizontal">
                            <div class="icon icon-info">
                                <i class="now-ui-icons design-2_html5"></i>
                            </div>
                            <div class="description">
                                <h5 class="info-title">For Developers</h5>
                                <p>The moment you use Now UI Kit, you know you’ve never felt anything like it. With a
                                    single use, this powerfull UI Kit lets you do more than ever before. </p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-danger">
                                <i class="now-ui-icons design_palette"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">For Designers</h4>
                                <p>Divide details about your product or agency work into parts. Write a few lines about
                                    each one. A paragraph describing a feature will be enough.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="phone-container">
                            <img v-lazy="'img/iphone2.png'"/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-horizontal">
                            <div class="icon icon-primary">
                                <i class="now-ui-icons design-2_ruler-pencil"></i>
                            </div>
                            <div class="description">
                                <h5 class="info-title">Bootstrap Grid</h5>
                                <p>Divide details about your product or agency work into parts. Write a few lines about
                                    each one. A paragraph describing a feature will be enough.</p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-success">
                                <i class="now-ui-icons files_single-copy-04"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Example Pages Included</h4>
                                <p>Divide details about your product or agency work into parts. Write a few lines about
                                    each one. A paragraph describing a feature will be enough.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-3 section-image" style="background-image: url('img/pricing3.jpg')" id="pricing-3">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto text-center">
                        <h2 class="title">Pick the best plan for you</h2>
                        <h4 class="description">You have Free Unlimited Updates on each package.</h4>
                        <div class="section-space"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 ml-auto">
                        <card type="pricing">
                            <h6 class="category">Freelance</h6>
                            <h1 class="card-title">
                                <small>$</small>
                                29
                                <small>/mo</small>
                            </h1>
                            <ul>
                                <li>1 GB of space</li>
                                <li>Limited Support</li>
                                <li>Support at $25/hour</li>
                                <li>Limited cloud access</li>
                            </ul>
                            <n-button type="primary" round>Get Started</n-button>
                        </card>
                    </div>
                    <div class="col-md-4 mr-auto">
                        <card type="pricing" raised color="orange">
                            <h6 class="category">Business</h6>
                            <h1 class="card-title">
                                <small>$</small>
                                59
                                <small>/mo</small>
                            </h1>
                            <ul>
                                <li>5 GB of space</li>
                                <li>UnLimited Support</li>
                                <li>Unlimited Downloads</li>
                                <li>Full cloud access</li>
                            </ul>
                            <n-button type="neutral" round>Get Started</n-button>
                        </card>
                    </div>
                </div>
            </div>
        </div>


        <div class="projects-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h2 class="title">We have many advantages</h2>
                        <h4 class="description">This is the paragraph where you can write more details about
                            your projects. Keep you user engaged by providing meaningful information.</h4>
                        <div class="section-space"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 ml-auto mt-5">
                        <info-section type="warning"
                                      icon="now-ui-icons users_single-02"
                                      title="Work With Any Team"
                                      description="Whether it’s for work or even the next family vacation, Trello helps your team."
                        />

                        <info-section type="warning"
                                      icon="now-ui-icons business_chart-bar-32"
                                      title="A Productivity Platform"
                                      description="Integrate the apps your team already uses directly into your workflow."
                        />

                        <info-section type="warning"
                                      icon="now-ui-icons arrows-1_refresh-69"
                                      title="Always In Sync"
                                      description="No matter where you are, Trello stays in sync across all of your devices."
                        />

                    </div>
                    <div class="col-md-5 mr-auto">
                        <card type="background" raised class="card-background-product"
                              style="background-image: url('img/project9.jpg')">
                            <h2 class="card-title">Trello lets you work.</h2>
                            <p class="card-description text-white">
                                Trello’s boards, lists, and cards enable you to organize and prioritize your
                                projects in a fun, flexible and rewarding way.
                            </p>
                            <badge type="neutral">Trello</badge>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="separator-line separator-primary"></div>

    </div>
</template>
<script>
    import {Card, InfoSection, Badge, Button, FormGroupInput, Tabs, TabPane} from '@/components';
    import NavLink from "../components/Navbar/NavLink";

    export default {
        components: {
            NavLink,
            Card,
            InfoSection,
            Badge,
            Tabs,
            TabPane,
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
        },
        data() {
            return {
                pricing5Tab: 'Expensive'
            }
        }
    }
</script>
<style scoped>

</style>
