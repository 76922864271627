<template>
  <div class="section section-download" id="#download-section" data-background-color="black">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="text-center col-md-12 col-lg-8">
          <h3 class="title">Zaufaj nam</h3>
          <h5 class="description">
            Jesteśmy firmą z podkarpacia, ale to nie problem odwiedzić Was nawet w odległych miejscach naszego kraju.
            Zapraszamy do współpracy, mamy jeszcze wolne terminy w sezonie {{ year }}.
          </h5>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <router-link to="/about-us" class="btn btn-primary btn-lg btn-round" role="button"> O nas </router-link>
          <router-link to="/contact" class="btn btn-primary btn-lg btn-simple btn-round" role="button">
            Kontakt
          </router-link>
        </div>
      </div>
      <br />

      <br />
      <br />
      <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h3>Dziękujemy za Wasze wsparcie!</h3>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <a
            target="_blank"
            href="https://www.twitter.com/etimingPL"
            class="btn btn-neutral btn-icon btn-twitter btn-round btn-lg"
            rel="tooltip"
            title="Follow us"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/etimingPL"
            class="btn btn-neutral btn-icon btn-facebook btn-round btn-lg"
            rel="tooltip"
            title="Like us"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
