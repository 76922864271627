<template>
    <div class="row">
        <div class="col-md-4 ml-auto mr-auto">
            <div class="info info-horizontal">
                <div class="icon icon-primary">
                    <i class="now-ui-icons media-2_sound-wave"></i>
                </div>
                <div class="description">
                    <h5 class="info-title">Marketing</h5>
                    <p class="description">
                        We've created the marketing campaign of the website. It was a very interesting
                        collaboration.
                    </p>
                </div>
            </div>
            <div class="info info-horizontal">
                <div class="icon icon-primary">
                    <i class="now-ui-icons media-1_button-pause"></i>
                </div>
                <div class="description">
                    <h5 class="info-title">Fully Coded in HTML5</h5>
                    <p class="description">
                        We've developed the website with HTML5 and CSS3. The client has access to the code
                        using GitHub.
                    </p>
                </div>
            </div>
            <div class="info info-horizontal">
                <div class="icon icon-info">
                    <i class="now-ui-icons users_single-02"></i>
                </div>
                <div class="description">
                    <h5 class="info-title">Built Audience</h5>
                    <p
                            class="description"
                    >There is also a Fully Customizable CMS Admin Dashboard for this product.</p>
                </div>
            </div>
        </div>
        <div class="col-md-5 mr-auto">
            <div class="card card-signup">
                <div class="card-body">
                    <h4 class="card-title text-center">Zapisz się</h4>
                    <div class="social text-center">

                        <!---->
                        <button @click="authenticate('facebook')"
                                class="btn btn-facebook btn-icon btn-round btn-default">
                            <i class="fab fa-facebook"></i>
                        </button>
                        <!---->

                        <h5 class="card-description">albo klasycznie</h5>
                    </div>


                    <form @submit.prevent="handleSubmit">

                        <alert type="success" v-show="alert">
                            <div class="alert-icon">
                                <i class="now-ui-icons ui-2_like"></i>
                            </div>
                            <strong>Dobra robota!</strong> Udało Ci się zarejestrować.
                        </alert>

                        <div v-if="submitted && $v.form.email.$error" class="invalid-feedback d-block">
                            <span class="error" v-if="!$v.form.email.required">Pole jest wymagane</span>
                            <span class="error" v-if="!$v.form.email.email">Podano niepoprawny e-mail</span>
                        </div>
                        <fg-input placeholder="E-mail..." v-model="form.email"
                                  addon-left-icon="now-ui-icons ui-1_email-85"></fg-input>


                        <div v-if="submitted && ($v.form.first_name.$error || $v.form.last_name.$error)"
                             class="invalid-feedback d-block">
                            <div class="error" v-if="!$v.form.first_name.required">Pole imię jest wymagane</div>
                            <div class="error" v-if="!$v.form.last_name.required">Pole nazwisko jest wymagane</div>
                        </div>
                        <div class="row">
                            <div class="col-5 first_name">
                                <fg-input placeholder="Imię..." v-model="form.first_name" v-on:keyup="setSex"
                                          addon-left-icon="now-ui-icons users_circle-08"></fg-input>
                            </div>
                            <div class="col-7 last_name">
                                <fg-input placeholder="Nazwisko..." v-model="form.last_name"
                                          addon-left-icon="now-ui-icons text_caps-small"></fg-input>
                            </div>
                        </div>

                        <div v-if="submitted && $v.form.birth_date.$error" class="invalid-feedback d-block">
                            <div class="error" v-if="!$v.form.birth_date.required">Pole jest wymagane</div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <fg-input>
                                    <el-date-picker v-model="form.birth_date" type="date" format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd" placeholder="Data urodzenia..."
                                                    popper-class="date-picker date-picker-primary"></el-date-picker>
                                </fg-input>
                            </div>
                            <div class="col-3 sex">
                                <n-switch v-model="sex" on-text="K" off-text="M"></n-switch>
                            </div>
                        </div>
                        <div v-if="submitted && $v.form.city.$error" class="invalid-feedback d-block">
                            <div class="error" v-if="!$v.form.city.required">Pole jest wymagane</div>
                        </div>
                        <fg-input placeholder="Miasto..." v-model="form.city"
                                  addon-left-icon="now-ui-icons travel_istanbul"></fg-input>
                        <fg-input placeholder="Klub..." v-model="form.club"
                                  addon-left-icon="now-ui-icons sport_user-run"></fg-input>
                        <div v-if="submitted && $v.form.phone.$error" class="invalid-feedback d-block">
                            <div class="error" v-if="!$v.form.phone.required">Pole jest wymagane</div>
                        </div>
                        <fg-input placeholder="Numer telefonu..." v-model="form.phone"
                                  addon-left-icon="now-ui-icons tech_mobile"></fg-input>

                        <div class="form-check">
                            <label for="conditions" class="form-check-label">
                                <input id="conditions" type="checkbox" class="form-check-input" v-model="conditions"/>
                                <span class="form-check-sign"></span>
                                Zgadzam się na <a @click="modals.terms = true" href="#">warunki</a> uczestnictwa.
                            </label>
                        </div>
                        <div class="card-footer text-center">
                            <button class="btn btn-round btn-primary btn-lg" :disabled="isDisabled">
                                <!---->
                                Zarejestruj
                                <!---->
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Tabs, TabPane, Modal, FormGroupInput, Switch, Alert} from "@/components";
    import {Popover, Tooltip, DatePicker} from 'element-ui';
    import {required, email} from 'vuelidate/lib/validators';
    import moment from 'moment'

    export default {
        props: ['event_id'],
        components: {
            Alert,
            [FormGroupInput.name]: FormGroupInput,
            [DatePicker.name]: DatePicker,
            [Switch.name]: Switch,
            required,
            email,
            filters: {
                capitalize: function (value) {
                    if (!value) return ''
                    value = value.toString()
                    return value.charAt(0).toUpperCase() + value.slice(1)
                },
            }
        },
        computed: {
            isDisabled: function () {
                return !this.conditions || this.submitted;
            }
        },
        methods: {
            authenticate: function (provider) {
                const this_ = this
                this.$auth.authenticate(provider).then(function () {
                    let token = this_.$auth.getToken()
                    this_.token = token
                    if (provider === 'facebook') {
                        this_.$http.get('https://graph.facebook.com/v2.11/me', {
                            params: {
                                fields: "id,first_name,last_name,email,birthday,location",
                                access_token: token,
                            }
                        }).then(function (response) {
                            this_.profile = JSON.stringify(response)
                            this_.form.first_name = response.data.first_name
                            this_.form.last_name = response.data.last_name
                            this_.form.email = response.data.email
                            this_.form.birthday = response.data.birthday
                            // this_.form.city = response.data.location.city
                        })
                    }
                })
            },
            handleSubmit(e) {
                this.submitted = true;
                this.form.event = this.event_id;

                this.$v.form.$touch();
                if (this.$v.form.$error) return
                // to form submit after this
                this.form.birth_date = this.dateFormat(this.form.birth_date);
                this.$http
                    .post(process.env.VUE_APP_BACKEND_URL + "/members/", this.form)
                    .then(response => {
                        this.data = response.data,
                            this.alert = true,
                            setTimeout(() => {
                                this.$emit('hide');
                                this.resetForm();
                            }, 3000)
                    })
            },
            resetForm: function () {
                this.submitted = false;
                this.alert = false;
                this.conditions = false;
                Object.keys(this.form).forEach(key => {
                    this.form[key] = ''
                })
            },
            dateFormat: function (date) {
                return moment(date).format('YYYY-MM-DD');
            },
            setSex: function () {
                if (this.form.first_name.slice(-1) == 'a') {
                    this.sex = true;
                } else {
                    this.sex = false;
                }
            },
        },
        validations: {
            form: {
                email: {required, email},
                first_name: {required},
                last_name: {required},
                birth_date: {required},
                city: {required},
                phone: {required},
            }
        },
        data: function () {
            return {
                baseUrl: null,
                token: '',
                profile: '',
                alert: false,
                data: "",
                sex: false,
                form: {
                    event: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    birth_date: '',
                    sex: this.sex ? 'f' : 'm',
                    city: '',
                    country: 'PL',
                    club: '',
                    phone: '',
                },
                submitted: false,
                pickers: {
                    datePicker: ''
                },
                conditions: false,
            };
        }
    };
</script>
<style scoped>

    /*#terms {*/
    /*    z-index: 2002;*/
    /*}*/

    /*#terms .modal-dialog {*/
    /*    max-width: 600px;*/
    /*    font-size: 0.9em;*/
    /*}*/

    /*#register {*/
    /*    z-index: 2001;*/
    /*}*/

    /*#register .first_name {*/
    /*    padding-right: 2px*/
    /*}*/

    /*#register .last_name, #register .sex {*/
    /*    padding-left: 2px*/
    /*}*/

    /*#register .bootstrap-switch {*/
    /*    margin-top: 7px;*/
    /*}*/

    /*#register .input-group, .form-group {*/
    /*    margin-bottom: 6px;*/
    /*}*/

    /*#register .el-input__icon {*/
    /*    margin-left: 8px;*/
    /*}*/

    /*#register .el-input__prefix, .el-input__suffix {*/
    /*    color: #555555;*/
    /*}*/

    /*#register .el-date-editor.el-input .el-input__inner {*/
    /*    padding-left: 50px !important;*/
    /*}*/

    /*#register .modal-content {*/
    /*    background-color: #000;*/
    /*    height: 90%;*/
    /*    position: relative;*/
    /*    overflow-y: auto;*/
    /*}*/

    /*#register .modal-content:before {*/
    /*    position: absolute;*/
    /*    background: url("/img/bg18.jpg") !important;*/
    /*    content: "";*/
    /*    opacity: 0.3;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/

    /*#register .mr-auto {*/
    /*    margin-right: auto !important;*/
    /*    margin-bottom: 20px;*/
    /*}*/

    /*.modal .modal-dialog {*/
    /*    max-width: 1200px;*/
    /*    height: 100%;*/
    /*}*/

    /*.btn.btn-twitter {*/
    /*    background-color: #55acee;*/
    /*    color: #fff !important;*/
    /*}*/

    /*.btn.btn-facebook {*/
    /*    background-color: #3b5998;*/
    /*    color: #fff !important;*/
    /*}*/

    /*.modal.show {*/
    /*    background-color: rgba(0, 0, 0, 0.3);*/
    /*}*/

    /*.section-image .card-pricing.card-plain ul li,*/
    /*.section-image .info-title,*/
    /*.section-image .info.info-horizontal .icon i {*/
    /*    color: #fff;*/
    /*}*/

    /*.info-horizontal .icon {*/
    /*    float: left;*/
    /*    margin-right: 20px;*/
    /*}*/

    /*.info-horizontal .icon > i {*/
    /*    font-size: 2em;*/
    /*}*/

    /*.info.info-horizontal .icon i, .info-title {*/
    /*    color: #e9ecef !important;*/
    /*}*/

    /*.info-horizontal {*/
    /*    text-align: left !important;*/
    /*}*/

    /*.info-horizontal .description {*/
    /*    overflow: hidden;*/
    /*}*/

</style>
