<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Etiming",
    // all titles will be injected into this template
    titleTemplate: "Pomiar czasu | %s",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Jesteś organizatorem zawodów sportowych i szukasz kogoś kto zajmuje się pomiarem czasu, trafiłeś idealnie. Zespół eTiming może zapewnić wszystko co jest związane z pomiarem i organizacją imprez sportowych. Zapisy on-line, obsługa biura zawodów. Możemy również zapewnić inne specjalnie przygotowane usługi, które sprawią że Wasze zawody będą na światowym poziomie.",
      },
    ],
  },
};
</script>
